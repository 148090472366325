<script setup lang="ts"></script>

<template>
    <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.875 7.44828C6.875 6.41988 6.03553 5.58621 5 5.58621H1.875C0.839466 5.58621 0 6.41988 0 7.44828V12.4138C0 13.4422 0.839466 14.2759 1.875 14.2759L18.125 14.2759C19.1605 14.2759 20 13.4422 20 12.4138V7.44828C20 6.41988 19.1605 5.58621 18.125 5.58621H15C13.9645 5.58621 13.125 6.41988 13.125 7.44828V8.68966C13.125 9.71805 12.2855 10.5517 11.25 10.5517H8.75C7.71447 10.5517 6.875 9.71805 6.875 8.68966V7.44828Z"
            fill="currentColor"
        />
        <path
            d="M0 16.7586C0 16.073 0.559644 15.5172 1.25 15.5172L18.75 15.5172C19.4404 15.5172 20 16.073 20 16.7586C20 17.4442 19.4404 18 18.75 18H1.25C0.559644 18 0 17.4442 0 16.7586Z"
            fill="currentColor"
        />
        <path
            d="M8.57592 6.6235C8.67637 6.02493 9.19786 5.58621 9.80891 5.58621H10.1911C10.8021 5.58621 11.3236 6.02493 11.4241 6.6235L11.6324 7.86488C11.7594 8.62154 11.1718 9.31034 10.3994 9.31034H9.60058C8.82815 9.31034 8.2406 8.62154 8.36758 7.86488L8.57592 6.6235Z"
            fill="currentColor"
        />
        <path
            d="M1.55031 0.752377C1.74729 0.295941 2.19921 0 2.69925 0H17.3008C17.8008 0 18.2527 0.295941 18.4497 0.752376L19.2533 2.61444C19.6068 3.43359 19.0017 4.34483 18.1043 4.34483H1.89567C0.998279 4.34483 0.393241 3.43359 0.746742 2.61444L1.55031 0.752377Z"
            fill="currentColor"
        />
    </svg>
</template>
